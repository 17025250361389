@media (min-width: 1536px) {
  .row {
    align-items: center;
  }
}

.col {
  padding: 32px !important;
}
@media (min-width: 768px) {
  .col {
    padding: 0 203px !important;
  }
}
@media (min-width: 992px) {
  .col {
    padding: 0 !important;
    /* padding-left: 104px !important; */
  }
}

.mountains {
  width: 288px !important;
  max-width: 288px !important;
  height: 465px !important;
}
@media (min-width: 375px) {
  .mountains {
    width: 321px !important;
    max-width: 321px !important;
  }
}
@media (min-width: 992px) {
  .mountains {
    width: 598px !important;
    height: 865px !important;
    max-width: 598px !important;
    max-height: 865px !important;
  }
}

.rectangle_layer {
  width: 102px;
  height: 99px;
  bottom: 0px;
  right: -18px;
}
@media (min-width: 375px) {
  .rectangle_layer {
    right: 9px;
  }
}
@media (min-width: 768px) {
  .rectangle_layer {
    width: 190px;
    height: 185px;
    bottom: -60px;
    right: 168px;
  }
}
@media (min-width: 992px) {
  .rectangle_layer {
    bottom: -60px;
    right: -35px;
  }
}

.logo {
  width: 115px;
  height: 26px;
}
@media (min-width: 768px) {
  .logo {
    width: 192px;
    height: 43px;
  }
}

.title {
  font-family: "Hacen Algeria Bd";
  margin: 0;
}
@media (min-width: 576px) {
  .title {
    font-size: 25px;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 30px;
  }
}

.p {
  max-width: 825px;
}
@media (min-width: 992px) {
  .p {
    font-size: 25px;
  }
}

.question_icon {
  width: 37px;
  height: 45px;
  transform: matrix(0.97, -0.26, 0.26, 0.97, 0, 0);
  position: relative;
  top: -35px;
}
@media (min-width: 768px) {
  .question_icon {
    width: 60px;
    height: 75px;
  }
}

@media (min-width: 1536px) {
  .h3 {
    font-size: 40px !important;
  }
}

.ul {
  font-size: 13px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .ul {
    font-size: 22px;
  }
}

.li {
  list-style-image: url("../assets/images/Rectangle\ 10220.svg");
  padding-left: 18px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .li {
    padding-left: 30px;
  }
}

.wrapper_stack {
  position: relative;
  top: -100px;
  right: -189px;
  z-index: 10;
}
@media (min-width: 375px) {
  .wrapper_stack {
    right: -210px;
  }
}
@media (min-width: 768px) {
  .wrapper_stack {
    top: -125px;
    right: -260px;
  }
}
@media (min-width: 992px) {
  .wrapper_stack {
    right: -416px;
  }
}
@media (min-width: 1400px) {
  .wrapper_stack {
    right: -155px;
  }
}
