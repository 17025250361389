.h3 {
  font-size: 30px;
  font-family: "Hacen Algeria Bd";
  text-align: center;
  margin-bottom: 40px !important;
  color: var(--dark-red-color);
  margin-bottom: 50px !important;
}
@media (min-width: 992px) {
  .h3 {
    margin-bottom: 100px !important;
    font-size: 70px;
  }
}

.container {
  padding: 0 1.1rem;
}
@media (min-width: 375px) {
  .container {
    padding: 0 2.8rem;
  }
}
@media (min-width: 425px) {
  .container {
    padding: 0 4.3rem;
  }
}

.col {
  border: 1px solid var(--dark-black-color);
  width: 146px;
  height: 105px;
}
@media (min-width: 992px) {
  .col {
    width: 313px;
    height: 214px;
  }
}

.absolute {
  position: absolute;
  top: -34px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0 10px !important;
}
@media (min-width: 992px) {
  .absolute {
    top: -43px;
  }
}

.icon {
  width: 51px;
  height: 51px;
}
@media (min-width: 992px) {
  .icon {
    width: 110px;
    height: 110px;
  }
}

.p {
  font-size: 13px;
  font-family: "Hacen Algeria Bd";
  text-align: center;
}
@media (min-width: 1024px) {
  .p {
    font-size: 25px;
  }
}

.details {
  font-size: 10px;
  margin-bottom: 5px;
}

.row2 {
  padding: 0 32px !important;
  margin-top: 180px !important;
}
@media (min-width: 1440px) {
  .row2 {
    padding: 0 406px !important;
  }
}

.services_icons {
  width: 40px;
  height: 40px;
}
@media (min-width: 768px) {
  .services_icons {
    width: 109px;
    height: 109px;
  }
}

.h4 {
  font-size: 13px;
  font-family: "Hacen Algeria Bd";
}
@media (min-width: 768px) {
  .h4 {
    font-size: 28px;
  }
}

.p2 {
  font-size: 12px;
}
@media (min-width: 768px) {
  .p2 {
    font-size: 22px;
  }
}

.bg_pink {
  z-index: -1;
}
